import React, {useState} from 'react';
import { FaCartShopping } from "react-icons/fa6";
import Order from "./Order";

const showOrders = (props) => {
    let total = 0
    props.orders.forEach(el => total += Number.parseFloat(el.price));
    return (
        <>
            {props.orders.map(el => (
                <Order onDelete={props.onDelete} key={el.id} item={el} />
            ))}
            <p className="total">Сумма: {new Intl.NumberFormat().format(total)}$</p>
        </>
    )
}

const showNothing = () => {
    return (
        <div className="empty">
            <h2>Товаров нет</h2>
        </div>
    )
}

export default function Header(props) {
    let [cartOpen, setCartOpen] = useState(false);
    return (
        <header>
            <div>
                <span className="logo">House Staff</span>
                <ul className="nav">
                    <li>Про нас</li>
                    <li>Контакты</li>
                    <li>Кабинет</li>
                </ul>
                <FaCartShopping onClick={() => setCartOpen(cartOpen = !cartOpen)} className={`shop-cart-button ${cartOpen && 'active' }`} />

                {cartOpen && (
                    <div className="shop-cart">
                        {props.orders.length > 0 ? showOrders(props) : showNothing()}
                    </div>
                )}
            </div>
            <div className="presentation"></div>
        </header>
    )
}