import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Items from "./components/Items";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: [],
            items: [
                {
                    id: 1,
                    title: 'Стул серый',
                    img: 'grey-chair.jpg',
                    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing.',
                    category: 'chairs',
                    price: '49.99'
                },
                {
                    id: 2,
                    title: 'Стол',
                    img: 'table.jpg',
                    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing.',
                    category: 'tables',
                    price: '149.00'
                },
                {
                    id: 3,
                    title: 'Диван',
                    img: 'sofa.jpg',
                    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing.',
                    category: 'sofa',
                    price: '549.99'
                },
                {
                    id: 4,
                    title: 'Лампа',
                    img: 'lamp.jpg',
                    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing.',
                    category: 'light',
                    price: '25.00'
                },
                {
                    id: 5,
                    title: 'Стул белый',
                    img: 'white-chair.jpg',
                    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing.',
                    category: 'chairs',
                    price: '49.99'
                }
            ]
        }
        this.addToOrder = this.addToOrder.bind(this);
        this.deleteOrder = this.deleteOrder.bind(this);
    }
    render() {
        return (
            <div className="wrapper">
                <Header orders={this.state.orders} onDelete={this.deleteOrder} />
                <Items items={this.state.items} onAdd={this.addToOrder} />
                <Footer />
            </div>
        );
    }

    addToOrder(item) {
        let isInArray = false
        this.state.orders.forEach(el => {
            if(el.id === item.id)
                isInArray = true
        });
        if (!isInArray) {
            this.setState({orders: [...this.state.orders, item]});
        }
    }

    deleteOrder(id) {
        this.setState({orders: this.state.orders.filter(el => el.id !== id)});
    }
}

export default App;
